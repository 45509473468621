@font-face {
  font-family: 'Disket Mono';
  src: url('/fonts/DisketMono/Disket-Mono-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Disket Mono';
  src: url('/fonts/DisketMono/Disket-Mono-Bold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
